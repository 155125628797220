@page {
  size: A4 portrait;
  margin: 0;
}

.visit-prescription {
  width: 210mm;
  height: 297mm;
  border: 0.1mm solid black;
  padding: 6mm;
  margin: 0 5mm;
  font-size: 9pt;
}

.visit-prescription-actions {
  width: 210mm;
  margin: 5mm;
  display: flex;
  justify-content: space-between;
}

@media print {
  .visit-prescription {
    border: none;
    margin: 0;
    height: auto;
    width: auto;
  }

  .no-print {
    display: none;
  }
}

.visit-prescription header {
  margin: 0;
  display: flex;
}

.visit-prescription section {
  margin: 1mm 0;
}

.visit-prescription .logo {
  height: 10mm;
}

.visit-prescription .title {
  flex: 1;
  font-size: 10pt;
  text-align: center;
  text-transform: uppercase;
}

.visit-prescription .model-no {
  font-size: 7pt;
}

.visit-prescription .translated .ita {
  font-weight: 700;
}


.visit-prescription tbody td .translated .ita {
  font-weight: 500;
}

.visit-prescription .translated .eng {
  font-style: italic;
}

.visit-prescription .person-data {
  display: flex;
}

.visit-prescription .person-data .info {
  display: flex;
  align-items: center;
  margin-right: 20mm;
}

.visit-prescription .person-data .value {
  margin-left: 5mm;
  font-size: 11pt;
}

.visit-prescription .tick-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.visit-prescription .tick-box {
  border: 1px solid black;
  height: 8mm;
  width: 8mm;
  font-size: 8mm;
  line-height: 1;
  text-align: center;
  margin: 2mm 0;
}

.visit-prescription table {
  font-size: 7pt;
  border-collapse: collapse;
}

.visit-prescription thead {
  font-size: 9pt;
}

.visit-prescription tbody th {
  text-align: left;
  font-size: 7pt;
}

.visit-prescription th,
.visit-prescription td {
  vertical-align: top;
  border: .25mm solid black;
  padding: .25mm .5mm;
}

.visit-prescription table .eng {
  font-weight: 400;
}

.visit-prescription .description {
  width: auto;
  text-align: left;
  vertical-align: middle;
}

.visit-prescription .near {
  width: 25%;
}

.visit-prescription .far {
  width: 19%;
}

.visit-prescription .colors {
  width: 28%;
}

.visit-prescription .needs-glasses {
  display: flex;
  align-items: center;
}

.visit-prescription .needs-glasses .tick-wrapper {
  margin-left: 8mm;
}

.visit-prescription .needs-glasses .tick-box {
  margin-right: 1mm;
}

.visit-prescription .statement {
  font-size: 8pt;
  line-height: 1.1;
}

.visit-prescription .statement .ita {
  font-weight: 500;
}

.visit-prescription .statement .tick-wrapper {
 justify-content: flex-start;
}

.visit-prescription .statement .tick-box {
  margin-left: 3mm;
  margin-right: 5mm;
}

.visit-prescription .statement .translated {
  flex: 1;
}

.visit-prescription .statement hr {
  margin: 4mm 0 .5mm 0;
  border: 0;
  border-top: 0.1mm solid black;
}

.visit-prescription .sign-area {
  display: flex;
  justify-content: space-between;
}

.visit-prescription .sign-area .translated {
  border-bottom: 1px solid black;
  padding-bottom: 7mm;
  min-width: 45%
}
